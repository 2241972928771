import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { callApi } from '../api';
import { useBreadcrumbs } from '../components/Navigation/BreadcrumbsProvider';
import { useError } from '../components/Common/ErrorHandler';

type ItemsState<T> = { rows: T[], count: number };

export interface UseApiResponse<T> {
  item?: T;
  items: ItemsState<T>;
  loading: boolean;
}

export function useApi<T>(route: string): UseApiResponse<T> {
  const [items, setItems] = useState<ItemsState<T>>({ rows: [], count: 0 });
  const [item, setItem] = useState<T>();
  const [loading, setLoading] = useState<boolean>(true);
  const { showError } = useError();

  const { getAccessTokenSilently } = useAuth0();
  const setBreadcrumbs = useBreadcrumbs();

  useEffect(() => {
    const loadItems = async() => {
      setLoading(true);

      try {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
        const data = await callApi(token, route);

        if (Array.isArray(data?.rows)) {
          setItems({ rows: data.rows, count: data.count || data.rows.length });
        } else if (Array.isArray(data))  {
          setItems({ rows: data, count: data.length });
        } else {
          setBreadcrumbs(data);
          setItem(data);
        }
      } catch (e) {
        showError(e, route.split('?')[0]);
      } finally {
        setLoading(false);
      }
    };

    loadItems();
  }, [route, getAccessTokenSilently, setBreadcrumbs, showError]);

  return { items, item, loading };
}

import { useParams } from 'react-router';
import { Card, Grid, Typography, Tabs, TabPanel } from '@emburse/embark';

import { useApi, useTabs } from '../../hooks';
import { getCardForProgramAndCompany } from '../../api/routes';
import { ISerializedCard } from '../../types/api/serialized_card';
import { InfoBoxDataItem } from '../Common/InfoBoxDataItem';
import { CardTransactionList } from '.';
import { Loader } from '../Common/UI';

const cardTabs = [
  { label: 'Transactions' }
];

interface PageRouteParams {
  companyId: string,
  cardProgramId: string,
  cardId: string
}

const CardInfo = () => {
  const { companyId, cardProgramId, cardId } = useParams<PageRouteParams>();
  const { item, loading } = useApi<ISerializedCard>(getCardForProgramAndCompany(companyId, cardProgramId, cardId));
  const { tabValue, onTabChange } = useTabs(cardTabs);

  if (loading) {
    return <Loader />;
  }

  if (!item) {
    return <div>no item</div>;
  }

  const {
    number,
    user,
    company,
    name,
    state,
    accounting_integration_account_id
  } = item;

  const currency = user?.currency_code || company?.currency_code || 'USD';

  return (
    <>
      <Typography variant="h4">
        {name}
      </Typography>
      <Card>
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item>
            <Typography variant="h6">
              Card Information
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5} wrap="wrap">
          {number &&
            <Grid item xs={6} md={3}>
              <InfoBoxDataItem title="Card Number" value={number}/>
            </Grid>
          }
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="State" value={state}/>
          </Grid>
          {user &&
            <Grid item xs={6} md={3}>
              <InfoBoxDataItem title="User Name" value={user.name}/>
            </Grid>
          }
          {company &&
            <Grid item xs={6} md={3}>
              <InfoBoxDataItem title="Company" value={company.name}/>
            </Grid>
          }
          {accounting_integration_account_id &&
            <Grid item xs={6} md={3}>
              <InfoBoxDataItem title="Accounting mapping ID" value={accounting_integration_account_id}/>
            </Grid>
          }
        </Grid>
      </Card>
      <Tabs
        id="cards-tab-bar"
        tabs={cardTabs}
        onChange={onTabChange}
        initialIndex={tabValue}
      />
      <TabPanel value={tabValue} index={0}>
        <CardTransactionList companyId={companyId} cardProgramId={cardProgramId} cardId={cardId} currency={currency} />
      </TabPanel>
    </>
  );
};

export { CardInfo };

import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container } from '@emburse/embark-core';
import { EmburseThemeProvider } from '@emburse/embark';

import { BreadcrumbsProvider } from '../Navigation/BreadcrumbsProvider';
import { ErrorHandlerProvider } from '../Common/ErrorHandler';
import { CompanyList, CompanyInfo } from '../Companies';
import { UserInfo } from '../Users';
import { ExpenseInfo } from '../Expenses';
import { CreditInfo } from '../Credits';
import { Navigation } from '../Navigation';
import { CardProgramInfo } from '../Cards';
import { CardInfo } from '../Cards';
import { DebitInfo } from '../Debits';

import { designToken } from '../../designToken';
import './App.css';
import { UserProfileInfo } from '../UserProfiles';

export const routes = [
  { exact: true, path: '/', Component: CompanyList, title: 'All Companies' },
  { exact: true, path: '/companies/:companyId', Component: CompanyInfo, title: 'Company Info' },

  { exact: true, path: '/companies/:companyId/users', Component: CompanyInfo, title: 'Users' },
  { exact: true, path: '/companies/:companyId/users/:userId', Component: UserInfo, title: 'User Info' },

  { exact: true, path: '/companies/:companyId/expenses', Component: CompanyInfo, title: 'Expenses' },
  { exact: true, path: '/companies/:companyId/expenses/:expenseId', Component: ExpenseInfo, title: 'Expense Info' },

  { exact: true, path: '/companies/:companyId/users/:userId/user_profiles', Component: UserInfo, title: 'User Profiles' },
  { exact: true, path: '/companies/:companyId/users/:userId/user_profiles/:userProfileId', Component: UserProfileInfo, title: 'Profile Info' },

  { exact: true, path: '/companies/:companyId/users/:userId/expenses', Component: UserInfo, title: 'Expenses' },
  { exact: true, path: '/companies/:companyId/users/:userId/expenses/:expenseId', Component: ExpenseInfo, title: 'Expense Info' },

  { exact: true, path: '/companies/:companyId/users/:userId/credits', Component: UserInfo, title: 'Credits' },
  { exact: true, path: '/companies/:companyId/users/:userId/credits/:creditId', Component: CreditInfo, title: 'Credit Info' },

  { exact: true, path: '/companies/:companyId/card_programs', Component: CompanyInfo, title: 'Card Programs' },
  { exact: true, path: '/companies/:companyId/card_programs/:cardProgramId', Component: CardProgramInfo, title: 'Card Program Info' },

  { exact: true, path: '/companies/:companyId/card_programs/:cardProgramId/cards', Component: CardProgramInfo, title: 'Cards' },
  { exact: true, path: '/companies/:companyId/card_programs/:cardProgramId/cards/:cardId', Component: CardInfo, title: 'Card Info' },

  { exact: true, path: '/companies/:companyId/credits', Component: CompanyInfo, title: 'Credits' },
  { exact: true, path: '/companies/:companyId/credits/:creditId', Component: CreditInfo, title: 'Credit Info' },
  { exact: true, path: '/companies/:companyId/credits/:creditId/expenses', Component: CreditInfo, title: 'Expenses' },
  { exact: true, path: '/companies/:companyId/credits/:creditId/expenses/:expenseId', Component: ExpenseInfo, title: 'Expense Info' },

  { exact: true, path: '/companies/:companyId/debits', Component: CompanyInfo, title: 'Debits' },
  { exact: true, path: '/companies/:companyId/debits/:debitId', Component: DebitInfo, title: 'Debit Info' },
  { exact: true, path: '/companies/:companyId/debits/:debitId/expenses', Component: DebitInfo, title: 'Expenses' },
  { exact: true, path: '/companies/:companyId/debits/:debitId/expenses/:expenseId', Component: ExpenseInfo, title: 'Expense Info' }
];

const App = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, error, logout } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div><div>Loading ...</div>
        <button onClick={() => logout({ returnTo: window.location.origin })}>
          Log Out
        </button>
      </div>
    );
  }

  if (!isAuthenticated) {
    const urlParams = new URLSearchParams(window.location.search);
    const loginOptions = {
      invitation: urlParams.get('invitation') || undefined,
      organization: urlParams.get('organization') || undefined,
      organization_name: urlParams.get('organization_name') || undefined
    };

    loginWithRedirect(loginOptions);
    return (<></>);
  }

  return (
    <EmburseThemeProvider designToken={designToken}>
      <Router>
        <Navigation />
        <BreadcrumbsProvider>
          <Container classes={{ root: 'app-wrapper' }}>
            <ErrorHandlerProvider>
              <Switch>
                {routes.map(({ path, Component, exact }) => (
                  <Route key={path} path={path} exact={exact}>
                    <Component />
                  </Route>
                ))}
              </Switch>
            </ErrorHandlerProvider>
          </Container>
        </BreadcrumbsProvider>
      </Router>
    </EmburseThemeProvider>
  );
};

export { App };

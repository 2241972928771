export type ListUsersWhere = {
  name?: string;
}

export const listUsersForCompany = (companyId: string, limit: number = 0, offset: number = 0, where: ListUsersWhere = {}) => {
  const route = `/companies/${companyId}/users`;
  let query = `?limit=${limit}&offset=${offset}`;

  if (where.name) {
    query += `&name=${where.name}`;
  }

  return `${route}${query}`;
};

export const getUserForCompany = (companyId: string, userId: string) => {
  return `/companies/${companyId}/users/${userId}?include[]=personal_cards&include[]=corporate_cards&include[]=alternate_emails`;
};

export function listUserProfilesForCompany(companyId: string, userId: string, limit: number, offset: number) {
  const route = `/companies/${companyId}/users/${userId}/user_profiles`;

  const query = `?limit=${limit}&offset=${offset}&include[]=user`;

  return `${route}${query}`;
}

export function listUserDelegates(companyId: string, userId: string, limit: number, offset: number) {
  const route = `/companies/${companyId}/users/${userId}/delegates`;

  const query = `?limit=${limit}&offset=${offset}`;

  return `${route}${query}`;
}

export function listUserDelegateOf(companyId: string, userId: string, limit: number, offset: number) {
  const route = `/companies/${companyId}/users/${userId}/delegate_of`;

  const query = `?limit=${limit}&offset=${offset}`;

  return `${route}${query}`;
}

export function listUserReceipts(companyId: string, userId: string, limit: number, offset: number) {
  const route = `/companies/${companyId}/users/${userId}/receipts`;

  const query = `?limit=${limit}&offset=${offset}`;

  return `${route}${query}`;
}

export function listExpenseTagBucketsForUser(companyId: string, userId: string) {
  return `/companies/${companyId}/users/${userId}/expense_tag_buckets`;
}

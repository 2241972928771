import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CellParams, SortItem } from '@material-ui/data-grid';
import { Grid, ServerDataGrid, Typography } from '@emburse/embark';

import { SearchBar } from '../Common/SearchBar/SearchBar';
import { ISerializedCompany, CompanyState } from '../../types/api/serialized_company';
import { useApi, usePagination } from '../../hooks';
import { PER_PAGE_OPTIONS, COMPANIES_PER_PAGE } from '../../constants';
import { FilterBar } from '../Common/FilterBar';
import { Spacer } from '../Common/UI';
import { listCompanies, ListCompaniesWhere } from '../../api/routes/companies';
import { dateTimeFormatter } from '../../helpers/datagrid_formatters';

const columns = [
  { field: 'id', hide: true },
  {
    field: 'name', headerName: 'Company Name', flex: 1,
    renderCell: function render(params: CellParams) {
      const companyId = params.getValue('id') as string;
      return (
        <Link to={`/companies/${companyId}`}>{params.getValue('name')}</Link>
      );
    }
  },
  { field: 'created_at', headerName: 'Signup Date', type: 'dateTime', width: 200, sortable: false, valueFormatter: dateTimeFormatter },
  { field: 'state', headerName: 'Company State', width: 140, sortable: false }
];

const stateOptions: { label: string; value: CompanyState; }[] = [
  { label: 'Active', value: 'active' },
  { label: 'Closed', value: 'closed' },
  { label: 'Suspended', value: 'suspended' },
  { label: 'Billing Suspended', value: 'billing_suspended' }
];

// eslint-disable-next-line no-restricted-syntax
const CompanyList = () => {
  const [where, setWhere] = useState<ListCompaniesWhere>({});
  const [order, setOrder] =  useState<SortItem>({ field: 'created_at', sort: 'desc' });
  const { limit, offset, onPageChange } = usePagination(COMPANIES_PER_PAGE);
  const { items, loading } = useApi<ISerializedCompany>(listCompanies(limit, offset, order, where));

  const handleSearch = (searchQuery: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, name: searchQuery });
  };

  const handleFilter = (stateQuery: string) => {
    onPageChange(0, limit);
    setWhere({ ...where, state: stateQuery });
  };

  const onSortChange = (sortData: SortItem[]) => {
    setOrder((sortData && sortData[0]) || null);
  };

  return (
    <>
      <Typography variant="h4">
        Companies
      </Typography>
      <Grid container justify="space-between" spacing={2}>
        <Grid item>
          <SearchBar onSearch={handleSearch} label="Company Name"/>
        </Grid>
        <Grid item>
          <FilterBar label="Filter by state" options={stateOptions} onFilter={handleFilter} />
        </Grid>
      </Grid>
      <Spacer />
      <ServerDataGrid
        rowCount={items.count}
        rows={items.rows}
        columns={columns}
        autoHeight={true}
        loading={loading}
        pageSize={limit}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
      />
    </>
  );
};

export { CompanyList };

import { Typography } from '@emburse/embark';
import { ReactElement, CSSProperties } from 'react';

type Props = {
  title: string;
  value?: string;
  children?: ReactElement;
};

const style = {
  overflowWrap: 'break-word'
} as CSSProperties;

const InfoBoxDataItem = ({ title, value, children }: Props): ReactElement => {
  return (
    <>
      <Typography variant="subtitle1" style={style}>{title}:</Typography>
      {value && <Typography variant="body1" style={style}>{value}</Typography>}
      {children && <Typography variant="body1" style={style}>{children}</Typography>}
      {!(value || children) && <Typography variant="body1" color="textSecondary" style={style}>no data</Typography>}
    </>
  );
};

export { InfoBoxDataItem };

export const DATE_FORMAT = 'MMM D, YYYY @ h:mma';

export const PER_PAGE_OPTIONS = [5, 10, 25, 50];

export const ITEMS_PER_PAGE = 10;
export const COMPANIES_PER_PAGE = 10;
export const USERS_PER_PAGE = 5;
export const USER_PROFILES_PER_PAGE = 5;
export const USER_EXPENSES_PER_PAGE = 5;
export const CREDITS_PER_PAGE = 25;
export const DEBITS_PER_PAGE = 25;
export const PROGRAMS_PER_PAGE = 25;
export const SYNC_ERRORS_PER_PAGE = 25;
export const EXPENSE_TAGS_PER_PAGE = 25;
export const EXPENSES_PER_PAGE = 10;
export const CREDIT_EXPENSES_PER_PAGE = 10;
export const SUB_ACCOUNTS_PER_PAGE = 5;
export const CARDS_PER_PAGE = 10;
export const DEBIT_EXPENSES_PER_PAGE = 25;
export const EXPENSE_RULES_PER_PAGE = 5;
export const RECEIPTS_PER_PAGE = 10;
export const USER_EXPENSE_TAGS_PER_PAGE = 5;
export const USER_PROFILE_EXPENSES_PER_PAGE = 10;
export const NOTIFICATIONS_PER_PAGE = 25;
export const FEATURES_PER_PAGE = 10;

export async function callApi(token: string, route: string, query?: string) {
  let response: any;
  const url = `${process.env.REACT_APP_API_ENDPOINT}${route}${query || ''}`;
  const options = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'content-type': 'application/json'
    }
  };

  try {
    response = await fetch(url, options);
  } catch (e) {
    throw {
      statusText: 'Networking error',
      message: e.message
    };
  }

  const data = await response.json();
  if (response.ok) {
    // TODO: add return types
    return data;
  }

  throw {
    statusText: response.statusText,
    status: response.status,
    message: data.error
  };
}
